<template>
	<main class="articles-page">
		<section
			class="section section--larger"
			v-if="articles && articles.length > 0"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Articole
				</h2>
				<div class="columns is-multiline">
					<div
						class="column is-4 mb-5"
						v-for="article in articles"
						:key="article.id"
					>
						<component-article
							fromPage="home"
							:article="article"
						></component-article>
					</div>
				</div>
			</div>
		</section>
		<!-- <section class="section banner">
			<div class="container">
				<h2 class="title has-text-centered">
					Articole {{ subcategory.titlu }}
				</h2>
			</div>
		</section>
		<section class="section">
			<div class="container">
				<div class="columns is-multiline">
					<div
						class="column is-4"
						v-for="article in subcategory.articles"
						:key="article.id"
					>
						<component-article
							:article="article"
						></component-article>
					</div>
				</div>
			</div>
		</section> -->
	</main>
</template>

<script>
	import ComponentArticle from '@/components/ComponentArticle.vue';
	import { mapState } from 'vuex';

	export default {
		metaInfo() {
			return {
				title: `Aloe Vera Products - Articole`,
			};
		},
		components: {
			ComponentArticle,
		},
		data() {
			return {};
		},
		created() {
			
		},
		mounted() {
			// Trigger prerendering
			if (typeof window !== 'undefined') {
				window.dispatchEvent(new Event('prerender-trigger'));
			}
  		},
		computed: {
			...mapState('general', ['articles']),
		},
		methods: {
			
		},
	};
</script>

<style scoped lang="scss">
	.banner {
		background: linear-gradient(
			to bottom,
			lighten($gray, 10%),
			lighten($gray, 40%)
		);
		.title {
			color: $primary;
			font-size: 1.25rem;
			letter-spacing: 1px;
			text-transform: uppercase;
			text-align: center;
			@media (min-width: 481px) {
				font-size: 1.5rem;
			}
			@media (min-width: 769px) {
				font-size: 2rem;
			}
		}
	}
</style>
